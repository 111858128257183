<template>
  <tr>
    <td class="nowrap-column" style="width: 350px;">
      <div class="panel no-border mb-3" style="width: 350px;">
        <div class="panel-heading p-1" :style="car.sold ? 'background-color: rgba(40, 167, 69, 0.25) !important' : ''">
          <h5 class="car-title mb-0">
            #{{ car.id }}
            <strong class="margin-l-5">
              {{ car.title }}
            </strong>
          </h5>
          <span class="font-medium font-small mt-0">
          <span v-if="car.owner_type === 'client'">Клиент</span>
          <span v-if="car.owner_type === 'company'">Компания</span>
          <span v-if="car.owner_type === 'exchange'">Обмен/выкуп</span>
        </span>
          <span class="label label-success font-medium font-small-xs" style="padding: 2px 3px; margin-left: 5px;">
          {{ car.location === "owner" ? "У владельца" : "В салоне" }}
        </span>
        </div>
        <div class="panel-body p-1 mt-2">
          <div class="row">
            <div class="col-12 col-lg-6" style="padding-right: 3px;">
              <img v-if="car.main_photo" :src="car.main_photo" class="img-fluid" :alt="car.title"
                   style="max-height: 100px;">
              <img :alt="car.title" class="img-fluid" src="../../../assets/img/no-car-photo-240-140.png" v-else>

              <ul class="list-group list-group-unbordered">
                <li class="list-group-item no-border pt-0 pb-1">
                  <strong class="font-scale-1">{{ car.price }} $</strong>
                  <small v-if="car.old_price && car.is_top_price"
                         class="font-medium text-red text-muted margin-l-5 font-small">
                    {{ car.old_price }}$
                  </small>
                </li>
                <li class="list-group-item no-border medium-line-height text-muted font-small pb-1 pt-0"
                    v-if="[1, 2, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) || car.creator.id !== $store.getters['auth/authUser'].id">
                  {{ car.created_date }}
                  <small>
                    <br/>
                    Ответственный <strong>{{ car.creator.name }}</strong>
                  </small>
                </li>
                <li class="list-group-item no-border medium-line-height text-muted font-small pt-0"
                    v-if="car.editor">
                  {{ car.updated_date }}
                  <small>
                    <br/>
                    Обновил <strong>{{ car.editor.name }}</strong>
                  </small>
                </li>
              </ul>
            </div>
            <div class="col-12 col-lg-6" style="padding-left: 3px;">
              <strong>{{ car.branch.title }}</strong>
              <br/>
              <small v-if="car.branch.address">
                {{ car.branch.address }}
              </small>
              <br/>
              <small style="letter-spacing: -.035rem;">
                <strong v-if="car.specification.engine_title" class="text-black font-small">
                  {{ car.specification.engine_title }}
                </strong>
                {{ car.specification.fuel.title }}
                {{ car.specification.race }} тыс. км.
                <br/>
                {{ car.specification.gearbox.title }}
                / {{ car.specification.transmission.title }} привод
                <br/>
                <span v-if="car.origin">{{ car.origin.title }}</span>
                / <strong class="text-black font-small">{{ car.year }}</strong> г.
              </small>
              <ul class="list-group list-group-unbordered">
                <li class="list-group-item" style="border-bottom: 0; padding-bottom: 0;">
                  <span class="label label-primary">
                    <strong style="font-size: 15px; letter-spacing: .03rem;">
                      {{ car.specification.reg_number }}
                    </strong>
                  </span>
                </li>
              </ul>
              <ul class="list-group list-group-unbordered">
                <li class="list-group-item" style="border-bottom: 0; padding-bottom: 0;">
                  <span class="label label-primary">
                    <strong style="font-size: 14px; letter-spacing: .001rem;">
                      {{ car.specification.vin_code }}
                    </strong>
                  </span>
                </li>
              </ul>
              <ul v-if="car.owner" class="list-group list-group-unbordered font-small-xs">
                <li class="list-group-item" style="border-bottom: 0; padding-bottom: 0;">
                  <i class="fas fa-user-circle"></i>
                  <strong>
                    {{ car.owner.name }}
                  </strong>
                  <br />
                  <span class="text-muted font-medium font-small">
                    <i class="fa fa-phone-square text-blue" />
                    <a :href="'tel:+' + car.owner.phone">
                      {{ car.owner.phone }}
                    </a>
                  </span>
                </li>
              </ul>
            </div>
            <div v-if="car.sold"
                 class="col-12"
                 :style="car.sold ? 'background-color: rgba(40, 167, 69, 0.25) !important' : ''">
              Продано за
              <strong class="font-scale-1">
                {{ car.sold.sum }}$
              </strong>
              владельцу
              <strong class="font-scale-1">
                {{ car.sold.owner_sum }}$
              </strong>
              комиссия
              <strong class="font-scale-1">
                {{ (car.sold.sum - car.sold.owner_sum) }}$
              </strong>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-lg-12 pt-2">
              <router-link :to="{ name: 'car.details', params: { carId: car.id }}"
                           class="btn btn-success"
                           title="Детали"
                           type="button">
                <i class="fa fa-info-circle"></i>
              </router-link>

              <a v-if="!car.is_disabled" :href="$clientAppUrl + '/car/' + car.slug" :title="car.slug"
                 style="font-size: 1rem; margin-left: 6px;"
                 class="btn btn-info" target="_blank">
                <i class="fas fa-external-link-square-alt"></i>
              </a>

              <a :href="'tel:+' + car.owner.phone" class="btn btn-primary pointer" style="float: right;">
                <span>
                  <i class="fa fa-phone-square status-phone"/>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </td>
    <td class="nowrap-column">
      <ul class="list-group list-group-unbordered p-1">
        <li class="list-group-item" v-for="inspection in car.inspections">
          <a :href="reportSourcePath + inspection.car_id + inspection.car_id + '_' + inspection.id + '_report.jpg'"
             target="_blank"
             class="btn btn-success"
             style="margin: 3px 2px 2px 3px"
             title="Открыть"
             type="button">
            <i class="fas fa-file" style="margin-right: 3px;"></i>
            #{{ inspection.id }}, от {{ inspection.created_date }}
          </a>
        </li>
      </ul>
    </td>
  </tr>
</template>

<script>
import Vue from "vue";
import VuejsDialog from 'vuejs-dialog';

Vue.use(VuejsDialog);

export default {
  name: 'car-inspections-item',

  components: {

  },

  props: {
    car: {
      type: Object,
      default() {
        return this.$props.$_PaginationObject;
      }
    }
  },

  data: () => ({
    reportSourcePath: process.env.VUE_APP_API_URL + '/storage/cars/inspections/',
  }),

  methods: {

  }
}
</script>

<style scoped>

</style>
